
@import 'sass';

$fa-font-path: "../node_modules/font-awesome/fonts";
@import '~font-awesome/css/font-awesome.css';

@import 'bootstrap/scss/bootstrap';

body {
  background: linear-gradient(
    to bottom,
    #1e5a5a 0%,0%,
    #42b9c0 0%,10%,
    #339899 24%,
    #69c9ca 37%,
    #2d8586 77%,
    #256e6f 90%,
    #1e5d5d 100%
  );

  overflow: hidden;

  @include media-breakpoint-down(md) { overflow: auto; }
}

.form-label {
  text-transform: uppercase;
}

.form-control {
  width: 100% !important;
  &:focus { box-shadow: none !important; }
}

.ng-invalid:not(.ng-untouched) {
  border-color: $danger;
}

.cursor-pointer {
  cursor: pointer !important;
}
